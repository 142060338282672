.testimonial {
  background-color: rgb(255, 210, 217);
  padding: 4rem 3rem;
  border-radius: 10px;
  width: 40rem;
  margin-bottom: 10rem;
}
.testimonial .body {
  padding: 2rem 0;
}
.testimonial .body p {
  padding-bottom: 1rem;
}
.testimonial .footer {
  display: flex;
  justify-content: flex-end;
}
.nick-name {
  align-items: center;
  text-align: center;
  margin: 5rem 0;
}
