@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,800;1,700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-image: linear-gradient(50deg, rgb(255, 245, 246), rgb(255, 213, 220));
}
.app {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}

.gallery {
  margin-top: 10rem;
  height: 100vh;
}
.heading {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.heading p {
  font-size: 2rem;
  font-weight: 800;
  color: rgb(255, 110, 135);

}