
.box{
    position: relative;
    width: 13rem;
    height: 13rem;
    /* width: 17rem;
    height: 16rem; */
    transform-style: preserve-3d;
    animation: animate 80s linear infinite;

}
@keyframes animate {
    0%
    {
        transform: perspective(1000px) rotateY(0deg);
    }
    100%
    {
        transform: perspective(1000px) rotateY(-360deg);
    }
}

.box span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(calc(var(--i) * 36deg)) translateZ(400px);
    -webkit-box-reflect: below 0px linear-gradient(transparent, transparent, #0004);
     
}
.box span img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

